import React, { Component } from "react";

import SmokeCard from "./SmokeCard";
import NoResults from "../errors/NoResults";

import { isToday } from "date-fns";
import { isSmoke, isSupport } from "../../functions/filters";
import { sortByDate } from "../../functions/sorting";

export default class SmokeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smokesData: [],
      entriesData: [],
    };
  }

  dataMutation = (data) => {
    let orderedData = sortByDate(data).filter((entry) =>
      isToday(new Date(entry.smokeTime))
    );
    let supportData = orderedData.filter(isSupport);
    let smokesData = orderedData.filter(isSmoke);

    for (let i = 1; i < smokesData.length; i++) {
      let thisDate = new Date(smokesData[i].smokeTime);
      let prevDate = new Date(smokesData[i - 1].smokeTime);
      let difference = (thisDate.getTime() - prevDate.getTime()) / 1000 / 60;
      smokesData[i].sinceLast = difference;
    }

    this.setState({
      smokesData,
      entriesData: sortByDate(supportData.concat(smokesData)),
    });
  };

  componentDidMount() {
    this.dataMutation(this.props.data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.dataMutation(nextProps.data);
    }
  }

  // this callback passes a click on the delete button up to IndexPage/IndexPage
  sendDeleteToParent = (id) => {
    this.props.callbackFromParent(id);
  };

  render() {
    let entries;
    let numberOfSmokes = this.state.smokesData.length + 1;

    function smokeNumber(type) {
      if (type === "smoke") {
        numberOfSmokes--;
        return numberOfSmokes;
      }
    }

    if (this.state.entriesData.length > 0) {
      entries = this.state.entriesData
        .reverse()
        .map((smoke) => (
          <SmokeCard
            key={smoke.key}
            id={smoke.key}
            smokeTime={smoke.smokeTime}
            sinceLast={smoke.sinceLast || null}
            type={smoke.type}
            supportOption={smoke.supportOption}
            callbackFromParent={this.sendDeleteToParent}
            smokeNumber={smokeNumber(smoke.type)}
          />
        ));
      if (entries.length === 0) {
        entries = <NoResults />;
      }
    } else {
      entries = <NoResults />;
    }

    return (
      <div className="smoke-list">
        <p>Today:</p>
        <ul reversed>{entries}</ul>
      </div>
    );
  }
}
