/*
A simple line chart - the code is d3
This has a custom function to display the y axis correctly, every 10 minutes then an hour marker
x: date
y: time
*/

import React, { Component } from 'react';
import { scaleLinear, scaleTime } from 'd3-scale';
import { max } from 'd3-array';
import { select } from 'd3-selection';
import { timeDay, timeWeek } from 'd3-time';
import { axisBottom, axisLeft } from 'd3-axis';
import { line } from 'd3-shape';

export default class LineChartAverageByDay extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.chart = this.createBarChart.bind(this);
	}

	componentDidMount() {
		this.chart();
	}

	componentDidUpdate() {
		this.chart();
	}

	createBarChart() {
		const node = this.node;
		const dataMax = max(this.props.data, function(d) {
			return d.average;
		});

		const margin = {
				top: this.props.margin.top,
				right: this.props.margin.right,
				bottom: this.props.margin.bottom,
				left: this.props.margin.left,
			},
			width = this.props.size.x - margin.left - margin.right,
			chartWidth = width - margin.right,
			height = this.props.size.y - margin.top - margin.bottom,
			numberData = this.props.data.length,
			widthDivNumberData = chartWidth / numberData;

		var lineFunction = line()
			.x(function(d) {
				return xScale(new Date(d.day));
			})
			.y(function(d) {
				return yScale(d.average);
			});

		const xScale = scaleTime()
			.domain([
				new Date(this.props.data[0].day),
				new Date(this.props.data[numberData - 1].day),
			])
			.rangeRound([
				widthDivNumberData / 2,
				chartWidth - widthDivNumberData / 2 + margin.right,
			]);

		let xAxis = axisBottom().scale(xScale);

		if (numberData === 7) {
			xAxis.ticks(timeDay.every(1));
		} else {
			xAxis.ticks(timeWeek.every(1));
		}

		const yScale = scaleLinear()
			.domain([0, dataMax])
			.range([height, 0])
			.nice();
		const yAxis = axisLeft()
			.scale(yScale)
			.tickSize(width)
			.tickFormat(d => {
				if (d % 60 === 0) {
					return d / 60 + ' h';
				} else {
					return d % 60;
				}
			});

		select(node)
			.attr('width', width + margin.right + margin.left)
			.attr('height', height + margin.top + margin.bottom)
			.attr('class', 'd3-chart d3-chart-line')
			.selectAll('*')
			.remove();

		select(node)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
			.attr('width', width)
			.attr('height', height)
			.attr('class', 'axes');

		select(node)
			.select('.axes')
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('class', 'x-axis')
			.call(xAxis);

		select(node)
			.select('.axes')
			.append('g')
			.attr('height', height - margin.top - margin.bottom)
			.attr('transform', 'translate(' + width + ',0)')
			.attr('class', 'y-axis')
			.call(yAxis);

		select(node)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
			.attr('class', 'chart');

		select(node)
			.select('.chart')
			.selectAll('rect')
			.data(this.props.data)
			.enter();

		select(node)
			.select('.chart')
			.append('path')
			.attr('d', lineFunction(this.props.data))
			.style('fill', 'none')
			.style('stroke', this.props.stroke)
			.style('stroke-width', this.props.strokeWidth);

		select(node)
			.select('.chart')
			.selectAll('.text')
			.data(this.props.data)
			.enter()
			.append('text')
			.attr('class', 'label')
			.attr('x', (d, i) => i * widthDivNumberData + widthDivNumberData / 2)
			.attr('y', function(d) {
				return yScale(d.average) + 1;
			})
			.attr('dy', '10px')
			.text(function(d) {
				return d.average;
			});
	}

	render() {
		return (
			<div>
				<h2>{this.props.chartTitle}</h2>
				<svg ref={node => (this.node = node)}></svg>
			</div>
		);
	}
}
