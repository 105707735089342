/*
Parent component for the stats screen
API GET call to collect the data then various mutation:
- Format the date for plotting purposes
- Create two datasets, one that has all the information (allData)
   and one that has only smoke information, reduced to a single entry for each day (allDataFiltered)
- Each of these is then run through the selected filter from TimeFilter - this Component contains all the logic
*/

import React, { Component } from "react";
import { endOfDay, subDays } from "date-fns";

import Loading from "../utilities/Loading";
import NoResults from "../errors/NoResults";
import TimeFilter from "./TimeFilter";
import BarChartCountByDay from "./BarChartCountByDay";
import LineChartAverageByDay from "./LineChartAverageByDay";
import ScatterChartCountByDayByHour from "./ScatterChartCountByDayByHour";

import base from "../../base";
import { isSmoke, isSupport } from "../../functions/filters";
import { sortByDate } from "../../functions/sorting";

var dateFormat = require("dateformat");

export default class Stats extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      allData: [],
      smokesByDate: [],
      supportByDate: [],
      allDataFiltered: [],
      smokesByDateFiltered: [],
      supportByDateFiltered: [],
      size: {
        x: 370,
        y: 180,
      },
      margin: {
        top: 5,
        right: 15,
        bottom: 25,
        left: 35,
      },
      timeFilter: "7",
      loading: true,
      errorFound: false,
      user: localStorage.getItem("user"),
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  mutateData = () => {
    let orderedData = sortByDate(this.state.data);

    for (let i = 0; i < orderedData.length; i++) {
      let date = new Date(orderedData[i].smokeTime);
      orderedData[i].date = date;
      orderedData[i].day = dateFormat(date, "yyyy-mm-dd");
      orderedData[i].timeStamp = dateFormat(date, "HH:MM:ss");
      orderedData[i].count = 1;
      orderedData[i].sinceLast = null;
    }

    let supportData = orderedData.filter(isSupport);
    let smokesData = orderedData.filter(isSmoke);

    for (let i = 1; i < smokesData.length; i++) {
      let thisDate = new Date(smokesData[i].smokeTime);
      let prevDate = new Date(smokesData[i - 1].smokeTime);
      let difference = (thisDate.getTime() - prevDate.getTime()) / 1000 / 60;
      smokesData[i].sinceLast = difference;
    }

    let allData = sortByDate(supportData.concat(smokesData));

    let smokesByDate = smokesData.reduce(function (dates, entry) {
      let day = entry.day;
      let found = dates.find((entry) => entry.day === day);
      if (found) {
        found.count += entry.count;
        found.sinceLast += entry.sinceLast;
      } else {
        dates.push(entry);
      }
      return dates;
    }, []);
    smokesByDate.forEach((dates) => {
      dates.average = Math.round(dates.sinceLast / (dates.count - 1));
    });

    let supportByDate = supportData.reduce(function (dates, entry) {
      let day = entry.day;
      let found = dates.find((entry) => entry.day === day);
      if (found) {
        found.count += entry.count;
        found.sinceLast += entry.sinceLast;
      } else {
        dates.push(entry);
      }
      return dates;
    }, []);
    supportByDate.forEach((dates) => {
      dates.average = Math.round(dates.sinceLast / (dates.count - 1));
    });

    this.setState(
      {
        allData,
        smokesByDate,
        supportByDate,
      },
      () => this.filterData()
    );
  };

  filterData = () => {
    let allDataFiltered,
      smokesByDateFiltered,
      supportByDateFiltered,
      validDates = [];

    if (this.state.timeFilter === "0") {
      allDataFiltered = this.state.allData;
      smokesByDateFiltered = this.state.smokesByDate;
      supportByDateFiltered = this.state.supportByDate;
    } else {
      let endOfToday = endOfDay(new Date());
      validDates.push(dateFormat(endOfToday, "yyyy-mm-dd"));

      for (let i = 1; i < this.state.timeFilter; i++) {
        let newDate = subDays(endOfToday, i);
        validDates.push(dateFormat(newDate, "yyyy-mm-dd"));
      }

      function dayFilter(entry) {
        if (validDates.indexOf(entry.day) !== -1) {
          return true;
        } else {
          return false;
        }
      }

      allDataFiltered = this.state.allData.filter(dayFilter);
      smokesByDateFiltered = this.state.smokesByDate.filter(dayFilter);
      supportByDateFiltered = this.state.supportByDate.filter(dayFilter);
    }

    this.setState({
      allDataFiltered,
      smokesByDateFiltered,
      supportByDateFiltered,
      loading: false,
    });
  };

  getChartData = () => {
    base
      .fetch(this.state.user, {
        context: this,
        asArray: true,
        then(data) {
          this.setState(
            {
              data,
            },
            () => this.mutateData()
          );
        },
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorFound: true,
        });
      });
  };

  onTimeChange = (e) => {
    this.setState(
      {
        timeFilter: e.target.value,
      },
      () => this.filterData()
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loading message={`smokes`} />
        ) : this.state.errorFound ? (
          <NoResults />
        ) : (
          <div className="smoke-charts">
            <TimeFilter
              onTimeChange={this.onTimeChange}
              selectedTimeFilter={this.state.timeFilter}
            />
            <BarChartCountByDay
              data={this.state.smokesByDateFiltered}
              size={this.state.size}
              margin={this.state.margin}
              fill={"#bf1b06"}
              stroke={"#242424"}
              timeRange={this.state.timeFilter}
              chartTitle={"Total by day (smoke)"}
            />
            {this.state.supportByDateFiltered.length > 0 && (
              <BarChartCountByDay
                data={this.state.supportByDateFiltered}
                size={this.state.size}
                margin={this.state.margin}
                fill={"#42a019"}
                stroke={"#242424"}
                timeRange={this.state.timeFilter}
                chartTitle={"Total by day (support)"}
              />
            )}
            <LineChartAverageByDay
              data={this.state.smokesByDateFiltered}
              size={this.state.size}
              margin={this.state.margin}
              stroke={"#bf1b06"}
              strokeWidth={5}
              chartTitle={"Average time between"}
            />
            <ScatterChartCountByDayByHour
              data={this.state.allDataFiltered}
              size={{ x: this.state.size.x, y: 50 }}
              margin={this.state.margin}
              stroke={"#bf1b06"}
              strokeWidth={1}
              chartTitle={"Hourly count"}
            />
          </div>
        )}
      </div>
    );
  }
}
