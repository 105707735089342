/*
Component showing information for today as well as stopwatch
Code to initially render and to update if a smoke occurs
Only data for today's date is kept
A lot of this code is repeated elsewhere so is due to be moved to their own components
*/

import React, { Component } from "react";
import { isToday } from "date-fns";

import TimeFormat from "../utilities/TimeFormat";
import { isSmoke, isSupport } from "../../functions/filters";
import { sortByDate } from "../../functions/sorting";

export default class SmokeStatsBasics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smokesData: [],
      smokeDifference: 0,
      supportLength: 0,
      lastSmoke: new Date(),
      hours: "--",
      minutes: "--",
      seconds: "--",
    };
  }

  pad = (number) => {
    if (number === "--") {
      return number;
    } else if (Number.isNaN(number)) {
      return "--";
    }
    let s = String(number);
    while (s.length < 2) {
      s = "0" + s;
    }
    return s;
  };

  dataMutation = (data) => {
    let orderedData = sortByDate(data).filter((entry) =>
      isToday(new Date(entry.smokeTime))
    );
    let supportData = orderedData.filter(isSupport);
    let smokesData = orderedData.filter(isSmoke);
    let smokeDifference = 0;

    for (let i = 1; i < smokesData.length; i++) {
      let thisDate = new Date(smokesData[i].smokeTime);
      let prevDate = new Date(smokesData[i - 1].smokeTime);
      let difference = (thisDate.getTime() - prevDate.getTime()) / 1000 / 60;
      smokesData[i].sinceLast = difference;
      smokeDifference += difference;
    }

    let lastSmoke = null;
    if (smokesData.length > 0) {
      lastSmoke = new Date(smokesData[smokesData.length - 1].smokeTime);
    }

    this.setState({
      smokesData,
      lastSmoke,
      smokeDifference,
      supportLength: supportData.length,
    });
  };

  componentDidMount() {
    this.dataMutation(this.props.data);
    this.interval = setInterval(this.onTick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.dataMutation(nextProps.data);
  }

  onTick = () => {
    let difference = new Date() - this.state.lastSmoke;
    let differenceHours = Math.floor(difference / 3600 / 1000);
    let differenceMinutes = Math.floor(
      difference / 60 / 1000 - 60 * differenceHours
    );
    let differenceSeconds = Math.floor(
      difference / 1000 - 60 * 60 * differenceHours - 60 * differenceMinutes
    );

    this.setState({
      hours: differenceHours,
      minutes: differenceMinutes,
      seconds: differenceSeconds,
    });
  };

  render() {
    return (
      <div className="smoke-stats border-bottom-grey">
        {this.state.smokesData.length !== 0 ? (
          <div>
            <p className="p-large">
              You've smoked <strong>{this.state.smokesData.length}</strong>{" "}
              times today
              {this.state.supportLength !== 0 ? (
                <span className="smaller">
                  <br /> and you've used a smoking support{" "}
                  <strong>{this.state.supportLength}</strong> times
                </span>
              ) : null}
            </p>
            {this.state.smokeDifference !== 0 ? (
              <p>
                The average time between smokes is
                <br />
                <strong>
                  <TimeFormat
                    timeToFormat={
                      this.state.smokeDifference /
                      (this.state.smokesData.length - 1)
                    }
                  />
                </strong>
              </p>
            ) : (
              ""
            )}
            <p className="before-smoke-counter">Time since your last smoke:</p>
            <p className="smoke-counter">
              {this.pad(this.state.hours)}:{this.pad(this.state.minutes)}:
              {this.pad(this.state.seconds)}
            </p>
          </div>
        ) : (
          <div>
            <p className="p-large">You haven't smoked yet today</p>
          </div>
        )}
      </div>
    );
  }
}
