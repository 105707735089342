/*
Shows a single smokeList item
Information shown is different if it is a smoke or use of a smoking support
*/

import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';

import TimeFormat from '../utilities/TimeFormat';

const SmokeCard = props => {
	// this callback passes a click on the delete button up to smokeList/SmokeCard
	function sendDeleteToParent(e) {
		e.preventDefault();
		props.callbackFromParent(props.id);
	}

	return props.type === 'smoke' ? (
		<li>
			{/* A smoke */}
			<div className='item' data-smokenumber={props.smokeNumber}>
				<span className='text-smoke'>
					<strong>{dateFormat(props.smokeTime, 'HH:MM')}</strong>
				</span>
				{props.sinceLast !== null ? (
					<TimeFormat
						timeToFormat={props.sinceLast}
						stringBefore={' ('}
						stringAfter={')'}
					/>
				) : (
					` (first of the day)`
				)}
				<button onClick={sendDeleteToParent}>x</button>
			</div>
		</li>
	) : (
		<li className='skip-number'>
			{/* A support */}
			<div className='item'>
				<span className='text-support'>
					<strong>{dateFormat(props.smokeTime, 'HH:MM')}</strong>
				</span>{' '}
				({props.supportOption})<button onClick={sendDeleteToParent}>x</button>
			</div>
		</li>
	);
};

SmokeCard.propTypes = {
	id: PropTypes.string.isRequired,
	smokeTime: PropTypes.string.isRequired,
	sinceLast: PropTypes.number,
	type: PropTypes.string,
	supportOption: PropTypes.string,
	callbackFromParent: PropTypes.func.isRequired,
	smokeNumber: PropTypes.number,
};

export default SmokeCard;
