/*
A helper component to correctly turn a number of minutes into written output.
Eg 70 = (1 hour, 10 minutes)
Handles plurals and pre/post string additions, eg ( and )
*/

import React from 'react';
import PropTypes from 'prop-types';

const TimeFormat = props => {
	let time = props.timeToFormat;
	let hours = Math.floor(time / 60);
	let minutes = Math.floor(time - hours * 60);

	let hourString = 'hour';
	if (hours !== 1) {
		hourString = 'hours';
	}

	let minuteString = 'minute';
	if (minutes !== 1) {
		minuteString = 'minutes';
	}

	return (
		<span>
			{props.stringBefore}
			{hours >= 1 && `${hours} ${hourString}, `}
			{minutes} {minuteString}
			{props.stringAfter}
		</span>
	);
};

TimeFormat.propTypes = {
	timeToFormat: PropTypes.number.isRequired,
	stringBefore: PropTypes.string,
	stringAfter: PropTypes.string,
};

export default TimeFormat;
