/* 
Simple error message to display message from the API
*/

import React from 'react';
import PropTypes from 'prop-types';

const ApiError = props => (
  <div className="message message-error">
    <h3>Error</h3>
    <p>{props.message}</p>
  </div>
);

ApiError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ApiError;
