/*
Error message for no results
*/

import React from 'react';

const NoResults = () => {
  return(
    <li className="no-icon">
      There are no entries, perhaps you haven't smoked yet today?
    </li>
  );
}

export default NoResults;
