import { differenceInDays, endOfDay } from "date-fns";

export const isInDayRange = (entry, numberOfDays) => {
  let endOfToday = endOfDay(new Date());
  let entryTime = endOfDay(new Date(entry.smokeTime));
  let difference = differenceInDays(endOfToday, entryTime);
  return difference < numberOfDays;
};

export const isSmoke = (entry) => {
  if (entry.type === "smoke") {
    return true;
  } else {
    return false;
  }
};

export const isSupport = (entry) => {
  if (entry.type === "support") {
    return true;
  } else {
    return false;
  }
};
