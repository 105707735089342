/*
Generic 404 error message
Since this app runs in a iPhone webapp and the error message isn't visible, this error shouldn't be seen
*/

import React from 'react';

const NotFound = () => {
  return(
    <div className="main-content">
      <h3>404 Not Found</h3>
    </div>
  );
}

export default NotFound;
