/*
A simple set of radio buttons for choosing the amount of days to display
All the logic is in Stats.js
*/

import React from 'react';
import PropTypes from 'prop-types';

const TimeFilter = props => {
	return (
		<div className='radio-buttons grid-container grid-container-third'>
			<label
				className={props.selectedTimeFilter === '7' ? 'form-selected' : ''}
			>
				<span className='form-label'>7 days</span>
				<input
					type='radio'
					name='timeFilter'
					value='7'
					checked={props.selectedTimeFilter === '7'}
					onChange={props.onTimeChange}
				/>
			</label>
			<label
				className={props.selectedTimeFilter === '14' ? 'form-selected' : ''}
			>
				<span className='form-label'>14 days</span>
				<input
					type='radio'
					name='timeFilter'
					value='14'
					checked={props.selectedTimeFilter === '14'}
					onChange={props.onTimeChange}
				/>
			</label>
			<label
				className={props.selectedTimeFilter === '31' ? 'form-selected' : ''}
			>
				<span className='form-label'>31 days</span>
				<input
					type='radio'
					name='timeFilter'
					value='31'
					checked={props.selectedTimeFilter === '31'}
					onChange={props.onTimeChange}
				/>
			</label>
		</div>
	);
};

TimeFilter.propTypes = {
	onTimeChange: PropTypes.func.isRequired,
	selectedTimeFilter: PropTypes.string.isRequired,
};

export default TimeFilter;
