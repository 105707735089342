import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const Footer = ({ logout }) => (
  <footer className="border-bottom-grey">
    <ul className="main-nav">
      <li>
        <NavLink exact to="/">
          Smoke
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/support">
          Support
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/stats">
          Stats
        </NavLink>
      </li>
      <li>
        <button onClick={logout}>Log out</button>
      </li>
    </ul>
  </footer>
);

Footer.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Footer;
