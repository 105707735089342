/*
A simple set of radio buttons for choosing what form of smoking support you've used
All the logic is in SupportForm.js
*/

import React from 'react';
import PropTypes from 'prop-types';

const SupportOptions = props => {
	return (
		<fieldset className='form-row'>
			<legend>What?</legend>
			<div className='radio-buttons grid-container grid-container-third'>
				<label
					className={
						props.selectedOption === 'inhalator' ? 'form-selected' : ''
					}
				>
					<span className='form-label'>Inhalator</span>
					<input
						type='radio'
						name='timeFilter'
						value='inhalator'
						checked={props.selectedOption === 'inhalator'}
						onChange={props.onOptionChange}
					/>
				</label>
				<label
					className={props.selectedOption === 'lozenge' ? 'form-selected' : ''}
				>
					<span className='form-label'>Lozenge</span>
					<input
						type='radio'
						name='timeFilter'
						value='lozenge'
						checked={props.selectedOption === 'lozenge'}
						onChange={props.onOptionChange}
					/>
				</label>
				<label
					className={props.selectedOption === 'gum' ? 'form-selected' : ''}
				>
					<span className='form-label'>Gum</span>
					<input
						type='radio'
						name='timeFilter'
						value='gum'
						checked={props.selectedOption === 'gum'}
						onChange={props.onOptionChange}
					/>
				</label>
			</div>
		</fieldset>
	);
};

SupportOptions.propTypes = {
	onOptionChange: PropTypes.func.isRequired,
	selectedOption: PropTypes.string.isRequired,
};

export default SupportOptions;
