/*
Component showing information for yesterday (to compare how you are doing)
And a scatter plot for visual comparison
A lot of this code is repeated elsewhere so is due to be moved to their own components
*/

import React, { Component } from "react";

import TimeFormat from "../utilities/TimeFormat";
import ScatterChartCountByDayByHour from "../stats/ScatterChartCountByDayByHour";

import { isYesterday } from "date-fns";
import { isInDayRange, isSmoke, isSupport } from "../../functions/filters";
import { sortByDate } from "../../functions/sorting";

var dateFormat = require("dateformat");

export default class SmokeStatsYesterday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smokesLength: 0,
      smokeDifference: 0,
      supportLength: 0,
      dataByDay: [],
      margin: {
        top: 5,
        right: 15,
        bottom: 25,
        left: 35,
      },
    };
  }

  dataMutation = (newProps) => {
    let orderedData = sortByDate(newProps.data).filter((entry) =>
      isYesterday(new Date(entry.smokeTime))
    );
    let supportData = orderedData.filter(isSupport);
    let smokesData = orderedData.filter(isSmoke);
    let smokeDifference = 0;

    for (let i = 1; i < smokesData.length; i++) {
      let thisDate = new Date(smokesData[i].smokeTime);
      let prevDate = new Date(smokesData[i - 1].smokeTime);
      let difference = (thisDate.getTime() - prevDate.getTime()) / 1000 / 60;
      smokesData[i].sinceLast = difference;
      smokeDifference += difference;
    }

    let dataByDay = sortByDate(newProps.data)
      .filter((entry) => {
        return isInDayRange(entry, 2);
      })
      .map(function (dates) {
        let date = new Date(dates.smokeTime);
        let day = dateFormat(date, "yyyy-mm-dd");
        let timeStamp = dateFormat(date, "HH:MM:ss");
        let count = 1;
        let sinceLast = dates.sinceLast;
        let type = dates.type;
        return { date, day, timeStamp, count, sinceLast, type };
      });

    this.setState({
      smokesLength: smokesData.length,
      smokeDifference,
      supportLength: supportData.length,
      dataByDay,
    });
  };

  componentDidMount() {
    this.dataMutation(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.dataMutation(nextProps);
    }
  }

  render() {
    return (
      <div className="smoke-list">
        {this.state.dataByDay.length !== 0 ? (
          <div>
            <p>
              Yesterday you smoked {this.state.smokesLength} times, with an
              average gap of
              <TimeFormat
                timeToFormat={
                  this.state.smokeDifference / (this.state.smokesLength - 1)
                }
                stringBefore={" "}
              />
              , and used a smoking support {this.state.supportLength} times
            </p>
            <ScatterChartCountByDayByHour
              data={this.state.dataByDay}
              size={{ x: 344, y: 50 }}
              margin={this.state.margin}
              stroke={"#bf1b06"}
              strokeWidth={1}
            />
          </div>
        ) : (
          <p>&nbsp;</p>
        )}
      </div>
    );
  }
}
