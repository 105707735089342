/*
Header, currently only an "encouraging" message
*/

import React from 'react';

const Header = () => (
	<header className='border-bottom-grey'>
		<div className='website-name'>Stop Smoking</div>
	</header>
);

export default Header;
