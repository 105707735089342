/*
Indexpage showing today's information (SmokeStatsBasics), the smoke form, yesterday's information  (SmokeStatsYesterday)
API call for "recent" information (today and tomorrow)
ReactPullToRefresh plugin on SmokeStatsBasics block for updating information before other screens added
*/

import React from 'react';

import Loading from '../utilities/Loading';
import SmokeForm from '../smokeForm/SmokeForm';
import SmokeList from '../smokeList/SmokeList';
import SmokeStatsBasics from './SmokeStatsBasics';
import SmokeStatsYesterday from './SmokeStatsYesterday';
import base from "../../base";

export default class IndexPage extends React.Component {
	constructor() {
		super();
		this.state = {
			smokes: [],
			loading: true,
			errorFound: false,
			user: localStorage.getItem('user'),
		};
	}

	componentDidMount() {
		this.getSmokes();
	}

	deleteEntry = id => {
		//@TODO: Fix
		// axios
		// 	.delete(`${serverAddress}/zigarroa/delete/${id}`)
		// 	.then(response => {
		// 		this.getSmokes();
		// 	})
		// 	.catch(error => {
		// 		this.setState({
		// 			loading: false,
		// 			errorFound: true,
		// 		});
		// 	});
	};

	getSmokes = () => {
		base.fetch(this.state.user, {
			context: this,
			asArray: true,
			then(data){
				this.setState({
					smokes: data,
					loading: false,
				});
			}
		}).catch(error => {
			this.setState({
				loading: false,
				errorFound: true,
			});
		});;
	};

	render() {
		return (
			<div>
				{this.state.loading ? (
					<Loading message={`smokes`} />
				) : (
					<div>
						<SmokeStatsBasics data={this.state.smokes} />
						<SmokeForm reloadResults={this.getSmokes} />
						<SmokeStatsYesterday data={this.state.smokes} />
						<SmokeList
							data={this.state.smokes}
							callbackFromParent={this.deleteEntry}
						/>
					</div>
				)}
			</div>
		);
	}
}
