/*
A loading indicator.
This is on the to-do list
*/

import React from 'react';
import PropTypes from 'prop-types';

const Loading = props => {
	return (
		<div className='view-loading'>
			<p>loading {props.message}</p>
		</div>
	);
};

Loading.propTypes = {
	message: PropTypes.string.isRequired,
};

export default Loading;
