import React from "react";
import PropTypes from "prop-types";

const SignIn = (props) => (
  <div className="login-form border-bottom-grey">
    <button
      className="btn btn-action"
      onClick={() => props.authenticate("Github")}
    >
      Log in with Github
    </button>
  </div>
);

SignIn.propTypes = {
  authenticate: PropTypes.func.isRequired,
};

export default SignIn;
