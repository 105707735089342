import React from 'react';

import ApiError from '../errors/ApiError';
import SupportOptions from './SupportOptions';
import Datetime from 'react-datetime';
import base from "../../base";

export default class SmokeForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			smokeTime: undefined,
			saveTime: false,
			error: false,
			errorMessage: '',
			btnClassName: 'btn btn-action',
			btnValue: 'Record a support',
			btnDisabled: '',
			supportOption: 'inhalator',
			type: 'support',
			user: localStorage.getItem('user'),
		};
	}

	onChange = e => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		const state = this.state;
		state[name] = value;
		this.setState(state);
	};

	onTimeChange = value => {
		const state = this.state;
		state.smokeTime = value;
		state.saveTime = true;
		this.setState(state);
	};

	onOptionChange = e => {
		this.setState({
			supportOption: e.target.value,
		});
	};

	onSubmit = e => {
		e.preventDefault();
		window.scrollTo(0, 0);

		this.setState(
			{
				btnClassName: 'btn btn-thinking',
				btnValue: 'Thinking',
				btnDisabled: 'disabled',
			},
			() => {
				this.apiCall()
			}
		);
	};

	apiCall() {
		const {
			user,
			type,
			supportOption,
		} = this.state;
		let { smokeTime } = this.state;

		if(smokeTime === undefined){
			smokeTime = new Date();
		}

		//@TODO: fix if save time is checked

		base
      .push(user, {
        data: {
					type,
					supportOption,
          smokeTime: String(smokeTime),
        },
      })
      .then(() => {
        this.setState({
          error: false,
          btnClassName: "btn btn-success",
          btnValue: "Recorded",
          smokeTime: undefined,
          saveTime: false,
          showOptions: "none",
          optionsButtonsText: "Show",
				});
				
        setTimeout(() => {
					window.location = '/';
				}, 2000);
      })
      .catch((err) => {
        this.setState({
          error: true,
          errorMessage: err,
        });
      });
	}

	render() {
		return (
			<div className='smoke-form smoke-form-support'>
				{this.state.error && (
					<ApiError message={this.state.errorMessage} />
				)}

				<form onSubmit={this.onSubmit}>
					<div className='form-row'>
						<input
							className={this.state.btnClassName}
							type='submit'
							name='submit'
							value={this.state.btnValue}
							disabled={this.state.btnDisabled}
						></input>
					</div>

					<SupportOptions
						onOptionChange={this.onOptionChange}
						selectedOption={this.state.supportOption}
					/>

					<div className="hide">
						<div className='form-row'>
							<label className='form-label' htmlFor='time'>
								Time?
							</label>
							<Datetime
								name='time'
								id='time'
								defaultValue={new Date()}
								onChange={this.onTimeChange}
								value={this.state.smokeTime}
								dateFormat={false}
								inputProps={{ readOnly: 'readonly' }}
							/>
						</div>

						<div className='grid-container'>
							<div
								className={`form-checkbox form-selected-${this.state.saveTime}`}
							>
								<label>
									<span className='form-label'>Save time?</span>
									<input
										type='checkbox'
										name='saveTime'
										checked={this.state.saveTime}
										onChange={this.onChange}
									/>
								</label>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
