/*
If logged in, show the correct screen through BrowserRouter and Switch
If logged out, show the SignIn form
*/

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "./base";

import ErrorBoundary from "./components/errors/ErrorBoundary";
import NotFound from "./components/errors/NotFound";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import SignIn from "./components/utilities/SignIn";
import IndexPage from "./components/indexPage/IndexPage";
import Stats from "./components/stats/Stats";
import SupportForm from "./components/supportForm/SupportForm";

import "./scss/style.scss";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { uid: null };
  }

  componentDidMount() {
    // const signedInCheck = localStorage.getItem("signedIn");
    // if (signedInCheck === "success") {
    //   this.setState({ 'signedIn': true });
    // }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authHandler({ user });
      }
    });
  }

  authHandler = async (authData) => {
    // const store = await base.fetch(this.props.storeId, { context: this });
    // if (!store.owner) {
    //   await base.post(`${this.props.storeId}/owner`, {
    //     data: authData.user.uid,
    //   });
    // }
    if (authData.user.uid === "RxxcR4u9yvNZg3qOmjGhcE5pBhr1") {
      localStorage.setItem("user", authData.user.uid);
      this.setState({
        uid: authData.user.uid,
      });
    }
  };

  authenticate = (provider) => {
    const authProvider = new firebase.auth[`${provider}AuthProvider`]();
    firebaseApp.auth().signInWithPopup(authProvider).then(this.authHandler);
  };

  logout = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    this.setState({ uid: null });
  };

  render() {
    return (
      <BrowserRouter>
        <div className="container">
          <Header />
          <div className="main-content">
            <ErrorBoundary>
              {this.state.uid !== null ? (
                <div>
                  <Footer logout={this.logout} />
                  <Switch>
                    <Route exact path="/" component={IndexPage} />
                    <Route exact path="/stats" component={Stats} />
                    <Route exact path="/support" component={SupportForm} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              ) : (
                <SignIn authenticate={this.authenticate} />
              )}
            </ErrorBoundary>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
