import Rebase from "re-base";
import firebase from "firebase/app";
import 'firebase/database';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCG4QiMpa2l4Pi0Vi-jbsw5u-TZQTMiOkc",
  authDomain: "zigarroa-36045.firebaseapp.com",
  databaseURL: "https://zigarroa-36045.firebaseio.com",
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };

export default base;
