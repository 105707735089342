import React, { Component } from "react";
import { scaleLinear, scaleTime } from "d3-scale";
import { max } from "d3-array";
import { select } from "d3-selection";
import { timeDay, timeWeek } from "d3-time";
import { axisBottom, axisLeft } from "d3-axis";
import { startOfDay, subDays } from "date-fns";

export default class BarChartCountByDay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.chart = this.createBarChart.bind(this);
  }

  componentDidMount() {
    this.chart();
  }

  componentDidUpdate() {
    this.chart();
  }

  createBarChart() {
    const node = this.node;

    let chartData = this.props.data;

    const dataMax = max(this.props.data, function (d) {
      return d.count;
    });

    const margin = {
        top: this.props.margin.top,
        right: this.props.margin.right,
        bottom: this.props.margin.bottom,
        left: this.props.margin.left,
      },
      width = this.props.size.x - margin.left - margin.right,
      chartWidth = width,
      height = this.props.size.y - margin.top - margin.bottom,
      numberData = this.props.timeRange,
      widthDivNumberData = chartWidth / numberData;

    let endDate = startOfDay(new Date());
    let startDate = subDays(endDate, this.props.timeRange - 1);

    const xScale = scaleTime()
      .domain([new Date(startDate), new Date(endDate)])
      .rangeRound([
        widthDivNumberData / 2,
        chartWidth - widthDivNumberData / 2,
      ]);

    let xAxis = axisBottom().scale(xScale);

    if (numberData === "7") {
      xAxis.ticks(timeDay.every(1));
    } else {
      xAxis.ticks(timeWeek.every(1));
    }

    const yScale = scaleLinear().domain([0, dataMax]).range([height, 0]).nice();
    const yAxis = axisLeft().scale(yScale).tickSize(width);

    select(node)
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "d3-chart d3-chart-bar")
      .selectAll("*")
      .remove();

    select(node)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "axes");

    select(node)
      .select(".axes")
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x-axis")
      .call(xAxis);

    select(node)
      .select(".axes")
      .append("g")
      .attr("height", height - margin.top - margin.bottom)
      .attr("transform", "translate(" + width + ",0)")
      .attr("class", "y-axis")
      .call(yAxis);

    select(node)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("class", "chart");

    select(node)
      .select(".chart")
      .selectAll("rect")
      .data(chartData)
      .enter()
      .append("rect")
      .style("fill", this.props.fill)
      .style("stroke", this.props.stroke)
      .attr("x", (d) => xScale(new Date(d.day)) - widthDivNumberData)
      .attr("y", (d) => yScale(d.count))
      .attr("height", (d) => yScale(0) - yScale(d.count))
      .attr("width", widthDivNumberData);

    select(node)
      .select(".chart")
      .selectAll(".text")
      .data(chartData)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr(
        "x",
        (d) =>
          xScale(new Date(d.day)) + widthDivNumberData / 2 - widthDivNumberData
      )
      .attr("y", function (d) {
        return yScale(d.count) + 1;
      })
      .attr("dy", "10px")
      .text(function (d) {
        return d.count;
      });

    select(node).exit().remove();
  }

  render() {
    return (
      <div>
        <h2>{this.props.chartTitle}</h2>
        <svg ref={(node) => (this.node = node)}></svg>
      </div>
    );
  }
}
