/*
A scatter plot - the code is d3
Plots each smoke against the time it happened, with extra rows for each date. Colour based on type
Height of the chart is dynamic based on the amount of days to show
This chart is also used on the opening screen
x: time
y: date
*/

import React, { Component } from 'react';
import { scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { timeHour, timeDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { axisBottom, axisLeft } from 'd3-axis';
import { nest } from 'd3-collection';

export default class ScatterChartCountByHour extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.chart = this.createBarChart.bind(this);
	}

	componentDidMount() {
		this.chart();
	}

	componentDidUpdate() {
		this.chart();
	}

	createBarChart() {
		const node = this.node;

		let chartData = this.props.data;
		let chartDataSmokes = chartData.filter(item => item.type === 'smoke');

		let dataByDate = nest()
			.key(d => d.day)
			.entries(chartData);

		let dataByDateSmokes = nest()
			.key(d => d.day)
			.entries(chartDataSmokes);

		const margin = {
				top: this.props.margin.top,
				right: this.props.margin.right,
				bottom: this.props.margin.bottom,
				left: this.props.margin.left,
			},
			width = this.props.size.x - margin.left - margin.right,
			height = dataByDate.length * 20;

		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const todayMillis = today.getTime();

		let todayMonth = parseInt(today.getMonth()) + 1;
		const todayFormatted = `${today.getFullYear()}-${todayMonth
			.toString()
			.padStart(2, '0')}-${today.getDate()}`;

		var getTime = d => {
			let parts = d.timeStamp.split(/:/);
			let timePeriodMillis =
				parseInt(parts[0], 10) * 60 * 60 * 1000 +
				parseInt(parts[1], 10) * 60 * 1000 +
				parseInt(parts[2], 10) * 1000;

			let time = new Date();
			time.setTime(todayMillis + timePeriodMillis);
			return xScale(time);
		};

		var circleFillColour = type => {
			if (type === 'support') {
				return '#42a019';
			} else {
				return this.props.stroke;
			}
		};

		const xScale = scaleTime()
			.domain([
				new Date().setHours(0, 0, 0, 0),
				new Date().setHours(23, 59, 59, 0),
			])
			.rangeRound([0, width])
			.nice(timeDay);
		const xAxis = axisBottom()
			.scale(xScale)
			.ticks(timeHour.every(3))
			.tickFormat(timeFormat('%H:%M'));
		const xGridLine = axisBottom()
			.scale(xScale)
			.ticks(timeHour.every(3))
			.tickSize(-height)
			.tickFormat('');

		let extraDay = new Date(dataByDate[dataByDate.length - 1].key);

		const yScale = scaleTime()
			.domain([
				new Date(dataByDate[0].key) - 12 * 60 * 60 * 1000,
				new Date(extraDay.getTime() + 12 * 60 * 60 * 1000),
			])
			.range([height, 0]);
		const yAxis = axisLeft()
			.scale(yScale)
			.tickSize(width)
			.ticks(timeDay.every(1))
			.tickFormat(timeFormat('%d/%m'));

		function shiftYLabels(selection) {
			selection.selectAll('text').attr('transform', 'translate(0,-10)');
		}

		select(node)
			.attr('width', width + margin.right + margin.left)
			.attr('height', height + margin.top + margin.bottom)
			.attr('class', 'd3-chart d3-chart-scatter')
			.selectAll('*')
			.remove();

		select(node)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
			.attr('width', width)
			.attr('height', height)
			.attr('class', 'axes');

		select(node)
			.select('.axes')
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('class', 'x-axis')
			.call(xAxis);

		select(node)
			.select('.axes')
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('class', 'x-gridlines')
			.call(xGridLine);

		select(node)
			.select('.axes')
			.append('g')
			.attr('height', height)
			.attr('transform', 'translate(' + width + ',0)')
			.attr('class', 'y-axis y-axis-box')
			.call(yAxis)
			.call(shiftYLabels);

		select(node)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
			.attr('class', 'chart');

		dataByDate.forEach(d => {
			select(this.node)
				.select('.chart')
				.selectAll('.chart')
				.data(d.values)
				.enter()
				.append('circle')
				.attr('r', 3)
				.attr('cx', d => getTime(d))
				.attr('cy', d => yScale(new Date(d.day)))
				.style('fill', d => circleFillColour(d.type));
		});

		//want to start from the most recent day, which is at the end
		//also dataByDateSmokes is 0 indexed
		for (let i = dataByDateSmokes.length - 1; i > 0; i--) {
			// get length of both dates and work out which is largest
			let currentDateLength = dataByDateSmokes[i].values.length;
			let previousDateLength = dataByDateSmokes[i - 1].values.length;
			let numberOfItemsMin = Math.min(currentDateLength, previousDateLength);

			// loop through items and draw lines between each day for each smoke
			for (let j = 0; j !== numberOfItemsMin; j++) {
				select(node)
					.select('.chart')
					.append('line')
					.style('stroke', this.props.stroke)
					.style('stroke-width', 2)
					.style('stroke-opacity', 0.8)
					.attr('x1', getTime(dataByDateSmokes[i - 1].values[j]))
					.attr('y1', yScale(new Date(dataByDateSmokes[i - 1].values[j].day)))
					.attr('x2', getTime(dataByDateSmokes[i].values[j]))
					.attr('y2', yScale(new Date(dataByDateSmokes[i].values[j].day)));
			}
		}

		//place a white dot showing the current time
		select(node)
			.select('.chart')
			.append('circle')
			.attr('r', 4)
			.attr('cx', xScale(Date.now()))
			.attr('cy', yScale(new Date(todayFormatted)))
			.style('fill', '#FFFFFF');
	}

	render() {
		return (
			<div>
				<h2>{this.props.chartTitle}</h2>
				<svg ref={node => (this.node = node)}></svg>
			</div>
		);
	}
}
